<template>
  <!-- start page-wrapper -->
  <div
    class="page-wrapper"
    style="overflow: auto"
    @scroll="ScrollingHandler"
    id="plantilla-boda-1"
  >
     <!-- Start header -->
        <header id="header">
            <div class="wpo-site-header">
                <nav class="navigation navbar navbar-expand-lg navbar-light"
                    v-bind:class="isSticky ? stickyClass : ''"
                >
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div class="mobail-menu">
                                    <button type="button" class="navbar-toggler open-btn" style="margin-left: 30px;" @click="AbrirMenu()">
                                        <span class="sr-only">Toggle navigation</span>
                                        <span class="icon-bar first-angle"></span>
                                        <span class="icon-bar middle-angle"></span>
                                        <span class="icon-bar last-angle"></span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-6">
                                <div class="navbar-header">
                                    <a class="navbar-brand"><img @click="EnviarAPrincipal()"
                                            src="../assets-v2/img/logo letras negra N azul.png" alt=""></a>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-1 col-1">
                                <div id="navbar" class="collapse navbar-collapse navigation-holder">
                                    <button @click="CerrarMenu()" class="menu-close"><i class="ti-close"></i></button>
                                    <ul class="nav navbar-nav mb-2 mb-lg-0">
                                        <li class="menu-item-has-children">
                                            <a @click="scrollTo('inicio')">Inicio</a>
                                        </li>
                                        <li class="menu-item-has-children">
                                            <a @click="scrollTo('itinerario')">Itinerario</a>
                                        </li>
                                        <li class="menu-item-has-children">
                                            <a @click="scrollTo('hospedaje')">Ubicación</a>
                                        </li>
                                        <li class="menu-item-has-children">
                                            <a @click="scrollTo('hospedaje')">Hospedaje</a>
                                        </li>
                                        <li class="menu-item-has-children">
                                            <a @click="scrollTo('mesa-regalos')">Mesa de Regalos</a>
                                        </li>
                                        <li class="menu-item-has-children">
                                            <a @click="scrollTo('registro')">Asistencia</a>
                                        </li>
                                    </ul>
                                </div><!-- end of nav-collapse -->
                            </div>
                        </div>
                    </div><!-- end of container -->
                </nav>
            </div>
        </header>
        <!-- end of header -->

    <!-- BANNER START -->
    <section class="wpo-hero-slider wpo-hero-slider-s2" id="BannerCarrousel" ref="inicio">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="slide-inner slide-bg-image">
              <div class="fondo-negro">
                <div class="container-fluid">
                  <div class="slide-content">
                    <div data-swiper-parallax="300" class="slide-title">
                      <h2>Regina & Daniel</h2>
                    </div>
                    <div data-swiper-parallax="400" class="slide-text">
                      <p>22 de Noviembre del 2022, León Gto.</p>
                    </div>
                    <div class="border-1"></div>
                    <div class="border-2"></div>
                    <div class="border-3"></div>
                    <div class="border-4"></div>
                    <div class="s-img-1">
                      <img src="../assets/images/slider/shape3.png" alt="" />
                    </div>
                    <div class="s-img-2">
                      <img src="../assets/images/slider/shape4.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="gradient-overlay"></div> -->
            </div>
            <!-- end slide-inner -->
          </div>
          <!-- end swiper-slide -->
        </div>
      </div>
    </section>
    <!-- NAVBAR END-->

    <!-- COUNTDOWN -->
    <section id="contador" class="section-padding">
      <div class="container">
        <div class="row">
          <div class="wpo-section-title mb-4">
            <h2>Cuenta Regresiva</h2>
            <div class="section-title-img">
              <img src="../assets/images/section-title.png" alt="" />
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-12 col-lg-8">
            <div id="demo" class="row"></div>
          </div>
          <div class="col-12 col-md-10 col-lg-8"></div>
        </div>
      </div>
    </section>
    <!-- COUNTDOWN END  -->

    <!-- INICIO START -->
    <section class="couple-section section-padding" id="couple">
      <div class="container">
        <div class="row align-items-center">
          <div class="col col-xs-12">
            <div class="couple-area clearfix">
              <div class="text-grid bride">
                <div class="couple-img">
                  <img src="../assets-v2/img/novia.jpg" alt="" />
                </div>
                <h3>Regina González</h3>
                <p>Espacio para mensaje o palabras de la novia.</p>
              </div>
              <div class="middle-couple-pic">
                <img src="../assets-v2/img/boda.jpg" alt="" />
                <div class="frame-img">
                  <img src="../assets/images/couple/shape.png" alt="" />
                </div>
              </div>
              <div class="text-grid groom">
                <div class="couple-img">
                  <img src="../assets-v2/img/novio.jpg" alt="" />
                </div>
                <h3>Daniel Martínez</h3>
                <p>Espacio para mensaje o palabras de la novio.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end container -->
    </section>
    <!-- INICIO END -->

    <!-- WEATHER START -->
    <section class="" id="weather">
      <a
        class="weatherwidget-io"
        href="https://forecast7.com/es/21d13n101d69/leon/"
        data-label_1="LEÓN"
        data-label_2="WEATHER"
        data-theme="original"
        data-basecolor="#86a0b6"
        data-highcolor="#ffffff"
        >LEÓN GUANAJUATO</a
      >
    </section>
    <!-- WEATHER START END -->

    <!-- ITINERARIO START -->
    <section class="story-section section-padding" id="story" ref="itinerario">
      <div class="container">
        <div class="row">
          <div class="wpo-section-title">
            <h2>Itinerario</h2>
            <div class="section-title-img">
              <img src="../assets/images/section-title.png" alt="" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-xs-12">
            <div class="story-timeline">
              <div class="round-shape"></div>
              <div class="row">
                <div class="col col-lg-6 col-12">
                  <div class="story-text right-align-text">
                    <h3>Ceremonia religiosa</h3>
                    <span class="date">Noviembre 22, 2022</span>
                    <p>
                      Templo Expiatorio del Sagrado Corazón de Jesús
                      <br />
                      02:00 PM
                      <br />
                      Dress Code: Formal
                    </p>
                    <a
                      type="submit"
                      href="https://goo.gl/maps/XgRiYftQLox9ajsB9"
                      target="_blank"
                      class="theme-btn-s3 mt-4"
                    >
                      Ver Ubicación
                    </a>
                  </div>
                </div>
                <div class="col col-lg-6 col-12">
                  <div class="img-holder">
                    <img
                      src="../assets-v2/img/expiatorio.jpg"
                      alt
                      class="img img-responsive"
                    />
                    <div class="story-shape-img">
                      <img src="../assets/images/story/shape.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col col-lg-6 col-12">
                  <div class="img-holder right-align-text left-site">
                    <img
                      src="../assets-v2/img/hacienda.jpg"
                      alt
                      class="img img-responsive"
                    />
                    <div class="story-shape-img">
                      <img src="../assets/images/story/shape.png" alt="" />
                    </div>
                  </div>
                </div>
                <div class="col col-lg-6 col-12 text-holder">
                  <span class="heart">
                    <i class="fi flaticon-heart"></i>
                  </span>
                  <div class="story-text">
                    <h3>Recepción</h3>
                    <span class="date">Noviembre 22, 2022</span>
                    <p>
                      Hacienda Los Rincones
                      <br />

                      Dress Code: Formal <br />
                      04:00 PM
                    </p>
                    <a
                      type="submit"
                      href="https://goo.gl/maps/hpzGVJ4xM4Cmpb2H8"
                      target="_blank"
                      class="theme-btn-s3 mt-4"
                    >
                      Ver Ubicación
                    </a>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col col-lg-6 col-12 text-holder right-heart">
                  <span class="heart">
                    <i class="fi flaticon-calendar"></i>
                  </span>
                  <div class="story-text right-align-text">
                    <h3>TORNABODA</h3>
                    <span class="date">Noviembre 23, 2022</span>
                    <p>
                      Mansion E. Borbon
                      <br />
                      Dress Code: Formal <br />
                      02:00 PM
                    </p>
                    <a
                      type="submit"
                      href="https://g.page/MansionEBorbon?share"
                      target="_blank"
                      class="theme-btn-s3 mt-4"
                    >
                      Ver Ubicación
                    </a>
                  </div>
                </div>
                <div class="col col-lg-6 col-12">
                  <div class="img-holder right-align-text">
                    <img
                      src="../assets-v2/img/tornaboda.jpg"
                      alt
                      class="img img-responsive"
                    />
                    <div class="story-shape-img">
                      <img src="../assets/images/story/shape.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col offset-lg-6 col-lg-6 col-12 text-holder">
                  <span class="heart">
                    <i class="fi flaticon-wedding-rings"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- ITINERARIO END -->

    <section id="dress-code" class="section-padding" ref="mesa-regalos">
      <div class="container-fluid">
        <div class="row text-center justify-content-center align-items-center">
          <div class="col-12 col-md-3 p-0">
            <img src="../assets-v2/img/novios-1.jpg" alt="" />
          </div>
          <div class="col-12 col-md-6 gift">
            <div class="wpo-section-title mb-0">
              <h2>Mesa de Regalos</h2>
            </div>
            <p class="text">
              ¡Tu presencia en nuestra boda es el mejor regalo de todos! Si nos
              quieres hacer un regalo te dejamos nuestras mesas de regalo.
            </p>
            <div class="row images">
              <div class="col-12 col-md-6 mt-4 mb-4">
                <a
                  href="https://mesaderegalos.liverpool.com.mx/"
                  target="_blank"
                  ><img
                    src="../assets-v2/img/liverpool.png"
                    class="logos-gift"
                    alt=""
                /></a>
                <br />
                <a
                  type="submit"
                  class="theme-btn-s3 mt-2"
                  target="_blank"
                  href="https://mesaderegalos.liverpool.com.mx/"
                >
                  Ver Mesa
                </a>
              </div>
              <div class="col-12 col-md-6 mt-4 mb-4">
                <a href="https://www.amazon.com.mx/wedding" target="_blank"
                  ><img
                    src="../assets-v2/img/amazon.png"
                    class="logos-gift"
                    alt=""
                /></a>
                <br />
                <a
                  type="submit"
                  class="theme-btn-s3 mt-2"
                  target="_blank"
                  href="https://www.amazon.com.mx/wedding"
                >
                  Ver Mesa
                </a>
              </div>
            </div>
          </div>
          <div class="col-4 col-md-3 p-0 d-none d-md-block">
            <img src="../assets-v2/img/novios-2.jpg" alt="" />
          </div>
        </div>
      </div>
    </section>

    <!-- HOSPEDAJE START -->
    <section class="wpo-event-section section-padding pt-0" ref="hospedaje">
      <div class="container">
        <div class="row">
          <div class="wpo-section-title">
            <h2>Ubicación y Hospedaje</h2>
            <div class="section-title-img">
              <img src="../assets/images/section-title.png" alt="" />
            </div>
          </div>
        </div>
        <div class="wpo-event-wrap">
          <div class="row justify-content-center">
            <div class="col col-lg-4 col-md-6 col-12">
              <div class="wpo-event-item">
                <div class="wpo-event-text">
                  <h2>Ubicación</h2>
                  <img src="../assets-v2/img/hacienda_2.jpg" alt="" />
                  <ul>
                    <li>Hacienda los Rincones</li>
                    <li>
                      Carretera Santa Ana del Conde, Comunidad Bajío Bolas
                      Blancas, 37680 León, Gto.
                    </li>
                  </ul>
                  <a
                    target="_blank"
                    href="https://goo.gl/maps/fJeLamiV4P9KZGhq8"
                    class="theme-btn-s3"
                  >
                    Ver Mapa
                  </a>
                </div>
                <div class="event-shape-1">
                  <img src="../assets/images/event-shape-1.png" alt="" />
                </div>
                <div class="event-shape-2">
                  <img src="../assets/images/event-shape-2.png" alt="" />
                </div>
              </div>
            </div>
            <div class="col col-lg-4 col-md-6 col-12">
              <div class="wpo-event-item">
                <div class="wpo-event-text">
                  <h2>Radisson Poliforum</h2>
                  <img src="../assets-v2/img/radisson.jpg" alt="" />
                  <ul>
                    <li>Radisson Poliforum Plaza Hotel Leon</li>
                    <li>
                      Blvd. Adolfo López Mateos 2611 Ote, Barrio de Guadalupe,
                      37280 León, Gto.
                    </li>
                    <li>Código: *****</li>
                  </ul>
                  <a target="_blank" href="tel:4770000000" class="theme-btn-s3">
                    Reservar
                  </a>
                </div>
                <div class="event-shape-1">
                  <img src="../assets/images/event-shape-1.png" alt="" />
                </div>
                <div class="event-shape-2">
                  <img src="../assets/images/event-shape-2.png" alt="" />
                </div>
              </div>
            </div>
            <div class="col col-lg-4 col-md-6 col-12">
              <div class="wpo-event-item">
                <div class="wpo-event-text">
                  <h2>HS HOTSSON</h2>
                  <img src="../assets-v2/img/hotsson.jpg" alt="" />
                  <ul>
                    <li>HS HOTSSON Hotel León</li>
                    <li>
                      Blvd. Adolfo López Mateos 1102, Los Gavilanes, 37266 León,
                      Gto.
                    </li>
                    <li>Código: *****</li>
                  </ul>
                  <a target="_blank" href="tel:4770000000" class="theme-btn-s3">
                    Reservar
                  </a>
                </div>
                <div class="event-shape-1">
                  <img src="../assets/images/event-shape-1.png" alt="" />
                </div>
                <div class="event-shape-2">
                  <img src="../assets/images/event-shape-2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end container -->
    </section>
    <!-- HOSPEDAJE END -->

    <!--  MAP START -->
    <section class="wpo-contact-map-section2">
      <h2 class="hidden">Contact map</h2>
      <div class="wpo-contact-map2">
        <a
          href="https://goo.gl/maps/ouQXz3sfreeLaSnR7"
          target="_blank"
          id="link-mapa"
        ></a>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14900.053457050433!2d-101.5932911!3d20.9921014!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x85682f67a80fc14d!2sHacienda%20Los%20Rincones!5e0!3m2!1ses-419!2smx!4v1657562454340!5m2!1ses-419!2smx"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
    <!-- MAP END -->

    <!-- FORM START -->
    <section class="wpo-contact-section section-padding" id="RSVP" ref="registro">
      <div class="container">
        <div class="wpo-contact-section-wrapper">
          <div class="wpo-contact-form-area">
            <div class="wpo-section-title-s2">
              <div class="section-title-simg">
                <img src="../assets/images/section-title.png" alt="" />
              </div>
              <h2>Confirmar asistencia</h2>
              <div class="section-title-img">
                <div class="round-ball"></div>
              </div>
            </div>
            <form
              method="post"
              class="contact-validation-active"
              id="contact-form-main"
            >
              <div>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  id="name"
                  placeholder="Nombre Completo"
                  required
                />
              </div>
              <div>
                <input
                  type="number"
                  class="form-control"
                  name="phone"
                  id="phone"
                  placeholder="Teléfono"
                  required
                />
              </div>
              <div>
                <select name="service" class="form-control" required>
                  <option disabled="disabled" selected>¿Asistirás?</option>
                  <option>Si</option>
                  <option>No</option>
                </select>
              </div>
              <div>
                <select name="guest" class="form-control" required>
                  <option disabled="disabled" selected>Asistentes</option>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                  <option>05</option>
                </select>
              </div>
              <div>
                <input
                  type="text"
                  class="form-control"
                  name="extra"
                  id="extra"
                  placeholder="Notas extras..."
                />
              </div>
              <div class="submit-area">
                <a type="submit" class="theme-btn-s3"> Registrar asistencia </a>
                <div id="c-loader">
                  <i class="ti-reload"></i>
                </div>
              </div>
              <div class="clearfix error-handling-messages">
                <div id="success">Thank you</div>
                <div id="error">
                  Error occurred while sending email. Please try again later.
                </div>
              </div>
            </form>
            <div class="border-style"></div>
          </div>
          <div class="vector-1">
            <img src="../assets/images/contact/1.png" alt="" />
          </div>
          <div class="vector-2">
            <img src="../assets/images/contact/2.png" alt="" />
          </div>
        </div>
      </div>
    </section>
    <!--  FORM END  -->

    <!-- FOOTER START -->
    <footer class="wpo-site-footer">
      <div class="wpo-upper-footer">
        <div class="container-fluid">
          <div class="row text-center justify-content-center">
            <div class="col-12">
              <img
                src="../assets-v2/img/logo letras blanca N azul.png"
                alt=""
                class="footer-logo"
              />
            </div>
            <div class="col-12">
              <p>
                Las mejores invitaciones digitales |
                <a
                  href="https://invination.mx/"
                  target="_blank"
                  class="invination"
                  >invination.mx</a
                >
              </p>
            </div>
          </div>
        </div>
        <!-- end container -->
      </div>
    </footer>
    <!-- FOOTER END -->
  </div>
  <!-- end wpo-contact-pg-section -->
</template>

<script>
/* COUNTDOWN */
// Set the date we're counting down to
var countDownDate = new Date("Nov 22, 2022 19:00:00").getTime();
// Update the count down every 1 second
var x = setInterval(function () {
  // Get today's date and time
  var now = new Date().getTime();
  // Find the distance between now and the count down date
  var distance = countDownDate - now;
  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);
  // Output the result in an element with id="demo"
  document.getElementById("demo").innerHTML =
    "<div class='numbers-1 col-6 col-md-3'><p> " +
    days +
    "</p><br> <span> DIAS </span></div>" +
    "<div class='numbers-2 col-6 col-md-3'><p> " +
    hours +
    "</p><br> <span> HORAS </span></div>" +
    "<div class='numbers-3 col-6 col-md-3'><p> " +
    minutes +
    "</p><br> <span> MINUTOS </span></div>" +
    "<div class='numbers-4 col-6 col-md-3'><p> " +
    seconds +
    "</p><br> <span> SEGUNDOS </span></div>";
  // If the count down is over, write some text
  if (distance < 0) {
    clearInterval(x);
    document.getElementById("demo").innerHTML =
      "<div class='numbers-1 col-6 col-md-3'><p> " +
      0 +
      "</p><br> <span> DIAS </span></div>" +
      "<div class='numbers-2 col-6 col-md-3'><p> " +
      0 +
      "</p><br> <span> HORAS </span></div>" +
      "<div class='numbers-3 col-6 col-md-3'><p> " +
      0 +
      "</p><br> <span> MINUTOS </span></div>" +
      "<div class='numbers-4 col-6 col-md-3'><p> " +
      0 +
      "</p><br> <span> SEGUNDOS </span></div>";
  }
}, 1000);

!(function (d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (!d.getElementById(id)) {
    js = d.createElement(s);
    js.id = id;
    js.src = "https://weatherwidget.io/js/widget.min.js";
    fjs.parentNode.insertBefore(js, fjs);
  }
})(document, "script", "weatherwidget-io-js");

export default {
  data() {
    return {
        isSticky: false,
        stickyClass: 'is_sticky',
    };
  },
  methods: {
    EnviarAPrincipal() {
        this.$router.push({ path: "/" })
    },
    EnviarAlLogin() {
        this.$router.push({ path: "/login" })
    },
    ScrollingHandler(){
        var bodyRect = document.getElementById('BannerCarrousel').getBoundingClientRect().y
        if(bodyRect < -10){
            this.isSticky = true
        } else {
            this.isSticky = false
        }
    },
    /* eslint-disable */
    scrollTo(param){
        switch(param){
            case 'inicio':
                this.$refs['inicio'].scrollIntoView({ behavior: 'smooth' })
                document.getElementById('navbar').classList.remove('slideInn')
            break;
            case 'itinerario':
                this.$refs['itinerario'].scrollIntoView({ behavior: 'smooth' })
                document.getElementById('navbar').classList.remove('slideInn')
            break;
            case 'hospedaje':
                this.$refs['hospedaje'].scrollIntoView({ behavior: 'smooth' })
                document.getElementById('navbar').classList.remove('slideInn')
            break;
            case 'mesa-regalos':
                this.$refs['mesa-regalos'].scrollIntoView({ behavior: 'smooth' })
                document.getElementById('navbar').classList.remove('slideInn')
            break;
            case 'registro':
                this.$refs['registro'].scrollIntoView({ behavior: 'smooth' })
                document.getElementById('navbar').classList.remove('slideInn')
            break;
        }
        
    },
    AbrirMenu() {
        document.getElementById('navbar').classList.add('slideInn')
    },
    CerrarMenu() {
        document.getElementById('navbar').classList.remove('slideInn')
    }
  },
};
</script>

<style>
html {
  overflow-y: visible !important;
}
.navbar.is_sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  width: 100%;
  -webkit-animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
  animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
}
</style>
<style scoped>
@import "../assets/css/themify-icons.css";
@import "../assets/css/font-awesome.min.css";
@import "../assets/css/flaticon.css";
@import "../assets/css/bootstrap.min.css";
@import "../assets/css/animate.css";
@import "../assets/css/owl.carousel.css";
@import "../assets/css/owl.theme.css";
@import "../assets/css/slick.css";
@import "../assets/css/slick-theme.css";
@import "../assets/css/swiper.min.css";
@import "../assets/css/nice-select.css";
@import "../assets/css/owl.transitions.css";
@import "../assets/css/jquery.fancybox.css";
@import "../assets/css/magnific-popup.css";
@import "../assets/css/odometer-theme-default.css";
@import "../assets/css/style.css";
@import "../assets-v2/css/style_bodav1.css";
</style>
